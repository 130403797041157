import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import ImageWithBoundingBox from "components/admin/show/ImageWithBoundingBox";
import JsonModal from "components/reusable/JsonModal";
import ModalWrapper from "components/reusable/web_links/ModalWrapper";
import {
  createInitialEnvironment,
  DisplayEnvironment,
  getEnvironment,
} from "contexts/displayEnvironment";
import { useJob, useJobFileLinks } from "queries";
import type {
  Job,
  JobDetails,
  JobImage,
  JobLog,
  JobRef,
  JobReview,
} from "types/api/job";
import { biometricJobTypes, jobTypeToProductNameMap } from "util/selectors";
import ReRun from "./re_run";

function DisplayTitle({
  environment,
  jobId,
  jobType,
  partnerId,
  productType,
  sdk,
  sdkVersion,
}: {
  environment: string;
  jobId: string;
  jobType: number;
  partnerId: string;
  productType: string;
  sdk?: string;
  sdkVersion?: string;
}) {
  return (
    <div className="job-show-title">
      <h3>
        Partner {partnerId} Job{" "}
        <Link
          className="title-link"
          to={`/admin/${partnerId}/job_results/${environment}/${jobId}`}
        >
          {jobId}
        </Link>
        {jobType
          ? ` - ${jobTypeToProductNameMap[productType]} JT${jobType}`
          : ""}
      </h3>
      {sdk && sdkVersion && (
        <p className="sdk-info">
          SDK:{" "}
          <span className="sdk-details">
            {sdk} v{sdkVersion}
          </span>
        </p>
      )}
    </div>
  );
}

function DisplayLogs({ logs = [] }: { logs: JobLog[] | null }) {
  if (!logs || !Array.isArray(logs)) return <p>No logs available.</p>;
  if (logs.length === 0) return <p>No logs to display.</p>;

  return logs.map((log) => {
    const {
      id,
      ref_id,
      created_at: createdAt,
      partner_params: partnerParams,
    } = log;

    return (
      <React.Fragment key={id}>
        <hr />
        <p>
          <strong>Ref:</strong> {ref_id}
        </p>
        <p>
          <strong>Created at:</strong> {new Date(createdAt).toLocaleString()}
        </p>
        <p>
          <strong>Job Type:</strong> {partnerParams.job_type}
        </p>
        <p>
          <strong>User ID:</strong> {partnerParams.user_id}
        </p>
        <p>
          <strong>Job ID:</strong> {partnerParams.job_id}
        </p>
        <p className="contain-rawjson">
          <strong>Partner params:</strong> {JSON.stringify(partnerParams)}
        </p>
        <br />
        <JsonModal jsonValue={log} />
        <br />
      </React.Fragment>
    );
  });
}

function DisplayReferences({ references = [] }: { references: JobRef[] }) {
  return references.map((reference) => (
    <React.Fragment key={`reference-${reference.id}`}>
      <div>
        <hr />
        <p>
          <strong>Status:</strong> {reference.status}
        </p>
        <p className="contain-rawjson">
          <strong>Result Text:</strong>{" "}
          {JSON.stringify(reference.result.ResultText)}
        </p>
        <p>
          <strong>Result Code:</strong> {reference.result.ResultCode}
        </p>
        <p>
          <strong>Created at:</strong>{" "}
          {new Date(reference.created_at).toLocaleString()}
        </p>
        <br />
        <JsonModal jsonValue={reference} />
        <br />
      </div>
    </React.Fragment>
  ));
}

function DisplayMLResults({
  mlResults,
}: {
  mlResults: null | Record<string, { result_code: number }>;
}) {
  if (!mlResults) return <p>No ML results available.</p>;
  return Object.keys(mlResults).map((key) =>
    key !== "ref_id" ? (
      <div key={`ml-result-${key}`}>
        <p>
          <strong>{key.replace("_", " ")} result code:</strong>{" "}
          {Math.round(mlResults[key].result_code)}
        </p>
        <br />
        <JsonModal jsonValue={mlResults[key]} />
        <br />
      </div>
    ) : null,
  );
}

function DisplayRelatedJobs({
  relatedJobs = [],
  onShow,
}: {
  relatedJobs: Job["related_jobs"];
  onShow: (id: number) => void;
}) {
  return relatedJobs.map((relatedJob) => (
    <div className="related-jobs" key={relatedJob.id}>
      <p className="inline">
        {`Partner Job ID: ${relatedJob.job_id} - `}
        {`${jobTypeToProductNameMap[relatedJob.product_type]} JT${
          relatedJob.partner_params.job_type
        }`}
        {` - ${new Date(relatedJob.created_at).toLocaleString()}`}
      </p>
      <button
        onClick={() => onShow(relatedJob.id)}
        className="btn btn-primary inline"
        data-id={relatedJob.id}
      >
        Show
      </button>
    </div>
  ));
}

function DisplayFiles({
  fileLinks = [],
}: {
  fileLinks: { name: string; url: string }[];
}) {
  return (
    <div className="file-links-container">
      <ul className="file-links-list">
        {fileLinks
          .sort((a, b) => a.name.localeCompare(b.name))
          ?.map((file, index) => (
            <li key={index} className="file-link-item">
              {file.url ? (
                <a
                  href={file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="file-link"
                >
                  {file.name}
                </a>
              ) : (
                <span className="file-link-not-found">
                  {`${file.name} - not found`}
                </span>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
}

const humanReadableImageTypes = Object.freeze({
  0: "Selfie",
  1: "Document",
  2: "Selfie",
  3: "Document",
  4: "Liveness",
  5: "Document Back",
  6: "Liveness",
  7: "Document Back",
  8: "Misc Document",
  9: "ID Authority",
  10: "Misc Document",
  11: "ID Authority Document",
  12: "Potrait",
});

const imageGroups = Object.freeze({
  Document: [1, 3, 5, 7, 8, 10, 11],
  "Selfie and ID Authority": [0, 2, 9],
  Liveness: [4, 6],
  Potrait: [12],
});

function DisplayImages({
  images = [],
  boundingBox,
  mlResults,
}: {
  images: JobImage[];
  boundingBox?: number[];
  mlResults?: Job["ml_results"];
}) {
  const groupedImagesByType: Record<string, JobImage[]> = {};
  Object.entries(imageGroups).forEach(([groupName, groupImageTypes]) => {
    const subset = images
      .filter((image) => groupImageTypes.includes(image.image_type))
      .sort((a, b) =>
        `${a.image_type}-${a.file_name}-${a.enrolled_image}`.localeCompare(
          `${b.image_type}-${b.file_name}-${b.enrolled_image}`,
        ),
      );
    if (subset.length > 0) {
      groupedImagesByType[groupName] = subset;
    }
  });

  const ungroupedImages = images.filter(
    (image) => !Object.values(imageGroups).flat().includes(image.image_type),
  );

  if (ungroupedImages.length > 0) {
    groupedImagesByType.Ungrouped = ungroupedImages.sort((a, b) =>
      a.file_name.localeCompare(b.file_name),
    );
  }

  return (
    <>
      {Object.entries(groupedImagesByType).map(([groupName, imageGroup]) => (
        <div key={groupName}>
          <h4>{groupName} Images</h4>
          <div className="imagesContainer">
            {imageGroup.map((image) => {
              const imagePath = new URL(image.link).pathname.substring(1);
              const isSameS3Path =
                mlResults?.passive_liveness?.metadata?.s3_path === imagePath;

              return (
                <div
                  key={`${image.link}${image.enrolled_image}`}
                  className="image-wrapper"
                  style={{ position: "relative" }}
                >
                  {[0, 2].includes(image.image_type) && boundingBox ? (
                    <ImageWithBoundingBox
                      boundingBox={boundingBox}
                      url={image.link}
                      className="image"
                    />
                  ) : (
                    <img
                      src={image.link}
                      alt={image.file_name}
                      className="image"
                    />
                  )}

                  <span>
                    {image.enrolled_image ? (
                      <strong>Enrolled Image:</strong>
                    ) : null}{" "}
                    {humanReadableImageTypes[image.image_type as 0] ??
                      image.image_type}{" "}
                    - <a href={image.link}>{image.file_name}</a>
                    {isSameS3Path && <strong> (Matched S3 Path)</strong>}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </>
  );
}

function DisplayReviews({ reviews = [] }: { reviews: JobReview[] }) {
  // Sort reviews by updated_at in descending order
  const sortedReviews = [...reviews].sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );

  return (
    <div className="reviews-container">
      <h3>Job Reviews</h3>
      {sortedReviews.length === 0 ? (
        <p>No reviews available.</p>
      ) : (
        <table className="reviews-table">
          <thead>
            <tr>
              <th>Purpose</th>
              <th>Updated At</th>
              <th>Duration</th>
              <th>Author</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {sortedReviews.map((review) => {
              const createdAt = new Date(review.created_at);
              const updatedAt = new Date(review.updated_at);
              const durationMilliseconds =
                updatedAt.getTime() - createdAt.getTime();
              const durationMinutes = Math.floor(durationMilliseconds / 60000);
              const durationSeconds = (
                (durationMilliseconds % 60000) /
                1000
              ).toFixed(0);
              const duration =
                durationMinutes > 0
                  ? `${durationMinutes}m ${durationSeconds}s`
                  : `${durationSeconds}s`;

              return (
                <tr key={review.id}>
                  <td>
                    {review.complete ? (
                      <a
                        href={`/admin/reviews/${review.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {review.purpose}
                      </a>
                    ) : (
                      review.purpose
                    )}
                  </td>
                  <td>{updatedAt.toLocaleString()}</td>
                  <td>{review.complete ? duration : "-"}</td>
                  <td>{review.author || "N/A"}</td>
                  <td
                    className={
                      review.complete ? "status-complete" : "status-incomplete"
                    }
                  >
                    {review.complete ? "Complete" : "Incomplete"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

function DisplayCharges({
  walletTransaction,
}: {
  walletTransaction?: Job["wallet_transaction"];
}) {
  if (!walletTransaction) {
    return null;
  }

  return (
    <div>
      <p>Amount: {walletTransaction.amount}</p>
      <p>Balance After Transaction: {walletTransaction.current_balance}</p>
      <p>Billed Codes: {walletTransaction.result_codes.join(", ") || ""}</p>
      <p>Provider Cost: {walletTransaction.provider_cost || 0}</p>
    </div>
  );
}

function DisplayReRunButton({
  job,
  amountCharged,
}: {
  job: JobDetails;
  amountCharged?: string;
}) {
  const [showModal, setShowModal] = useState(false);

  if (!biometricJobTypes.includes(job.ran_as_job_type)) {
    return null;
  }

  return (
    <div className="smile-reference-show">
      <button
        onClick={() => setShowModal(!showModal)}
        className="btn btn-primary"
      >
        Re-run Job
      </button>
      <ModalWrapper
        isOpen={showModal}
        onClose={() => setShowModal(!showModal)}
        hideBackButton
      >
        <ReRun
          job={job}
          toggleModal={() => setShowModal(!showModal)}
          amountCharged={amountCharged}
        />
      </ModalWrapper>
    </div>
  );
}

function JobsShow() {
  const { environment } = useContext(DisplayEnvironment);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { data: job, isLoading: isLoadingJob } = useJob(
    { id },
    { enabled: !!id },
  );
  const isBiometricJob = job
    ? biometricJobTypes.includes(job.job.ran_as_job_type)
    : null;
  const { data: jobFileLinks, isLoading: isLoadingJobFileLinks } =
    useJobFileLinks({ id }, { enabled: !!isBiometricJob });

  useEffect(() => {
    sessionStorage.setItem("url", `/job/${getEnvironment()}/${id}`);
    createInitialEnvironment();
  }, [id, environment]);

  const goToJob = (id: number) => {
    history.push(`/admin/job/${getEnvironment()}/${id}`);
  };

  if (isLoadingJob) {
    return <div className="loader" />;
  }

  const boundingBox = job!.ml_results?.passive_liveness?.metadata?.bounding_box;

  return (
    <div className="legacy">
      <DisplayTitle
        environment={getEnvironment()}
        jobId={job!.job.job_id}
        jobType={job!.job.ran_as_job_type}
        partnerId={job!.job.partner_id}
        productType={job!.product_type}
        sdk={job!.job.sdk}
        sdkVersion={job!.job.sdk_version}
      />
      <DisplayReRunButton
        job={job!.job}
        amountCharged={job!.wallet_transaction?.amount}
      />
      <hr />
      <div className="smile-reference-show">
        <h3>Access logs</h3>
        <DisplayLogs logs={job!.logs} />
        <h3>Smile References</h3>
        <DisplayReferences references={job!.smile_references} />
        <DisplayReviews reviews={job!.reviews} />
        <h3>Inference Server Responses</h3>
        <DisplayMLResults mlResults={job!.ml_results} />
        <h3>Related Jobs</h3>
        <DisplayRelatedJobs relatedJobs={job!.related_jobs} onShow={goToJob} />
        {isBiometricJob && <h3>File Links (Biometric Jobs Only)</h3>}
        {isLoadingJobFileLinks && <div>Loading...</div>}
        {jobFileLinks && <DisplayFiles fileLinks={jobFileLinks.files} />}
        <h3>Images</h3>
        <DisplayImages
          images={job!.images}
          boundingBox={boundingBox}
          mlResults={job!.ml_results}
        />
        <h3>Charges</h3>
        <DisplayCharges walletTransaction={job!.wallet_transaction} />
      </div>

      <Link to="/admin/access_logs" className="btn btn-primary back-button">
        Back
      </Link>
    </div>
  );
}

export default JobsShow;
export {
  DisplayCharges,
  DisplayImages,
  DisplayLogs,
  DisplayReRunButton,
  DisplayReviews,
  DisplayTitle,
};
