import "components/reusable/DocumentVerificationReview/DocumentVerificationReview.scss";
import "typography.scss";

import React, { useLayoutEffect, useRef } from "react";

import DocumentImageViewer from "components/reusable/DocumentVerificationReview/DocumentImageViewer";
import DocumentReviewForm from "components/reusable/DocumentVerificationReview/DocumentReviewForm";
import ReviewHeader from "components/reusable/ReviewHeader";

const DocVReview = ({ review, completed, callback, next }) => {
  const ref = useRef(null);

  const { source, target } = review.images || {};
  const frontIdImage = source?.url || review.source;
  const backIdImage =
    target?.find(({ imageType }) => imageType.valueText === "id_card_back")
      ?.url || review.target;

  useLayoutEffect(() => {
    if (ref.current && !completed) {
      ref.current?.scrollIntoView({ behavior: "smooth", inline: "center" });
    }
  }, []);

  return (
    <div
      className="legacy container__content document_review-content dark_bg"
      ref={ref}
    >
      <div className="review-header">
        <ReviewHeader
          title="Document Review"
          review={review}
          completed={completed}
          onNextReview={next}
        />
      </div>
      <div className="review--id-card">
        <div className="documents_container">
          <section className="document_front">
            <DocumentImageViewer
              imageUrls={[frontIdImage]}
              imageType="id_card"
              enableBoundingBox
            />
          </section>
          {!backIdImage && (
            <section className="h1 no_document_back">No back of ID</section>
          )}
          {backIdImage && (
            <section className="document_back">
              <DocumentImageViewer
                imageUrls={[backIdImage]}
                imageType="id_card_back"
                enableBoundingBox
              />
            </section>
          )}
        </div>
        <div className="review_forms_container">
          <DocumentReviewForm
            review={review}
            completed={completed}
            callback={callback}
            next={next}
          />
        </div>
      </div>
      {completed && (
        <div className="block--decision">
          {review.smile_reference?.result?.ConfidenceValue && (
            <p
              className={
                review.smile_reference.result.ConfidenceValue === "100"
                  ? "success"
                  : "failure"
              }
            >
              {review.smile_reference.result.ResultText}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default DocVReview;
