import React from "react";
import { useDeepCompareEffect, useSetState } from "ahooks";
import { App } from "antd";
import classNames from "classnames";

import CompareReview from "components/reusable/CompareReview";
import DocVReview from "components/reusable/DocumentVerificationReview/DocVReview";
import IDCardReview from "components/reusable/IDCardReview";
import SpoofReview from "components/reusable/SpoofReview";
import ErrorAndMessageBar from "./error_and_message_bar";

const ReviewContainer = {
  compare: CompareReview,
  id_card: IDCardReview,
  docv: DocVReview,
  spoof: SpoofReview,
};

function ReviewsShow({ review, refetchReviews }) {
  const { notification } = App.useApp();

  useDeepCompareEffect(() => {
    // when data refreshs scroll to the top
    window.scrollTo(0, 0);
  }, [review]);

  const ReviewFormContainer = ReviewContainer[review.purpose];

  const onReviewSubmitted = (resp) => {
    if (resp.reviewsAndCount?.error || resp.error) {
      notification.error({
        message: resp.reviewsAndCount?.error || resp.error,
      });
    }
    if (resp.reviewsAndCount?.message || resp.message) {
      notification.info({
        message: resp.reviewsAndCount?.message || resp.message,
      });
    }
  };

  return (
    <div
      className={classNames("review", {
        "open-review":
          review.purpose === "id_card" || review.purpose === "docv",
        "spoof-container review": review.purpose === "spoof",
      })}
    >
      <ReviewFormContainer
        review={review}
        next={refetchReviews}
        completed={review.complete}
      />
    </div>
  );
}

export default ReviewsShow;
