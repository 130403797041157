import React, { useState } from "react";

import DocumentImageViewer from "components/reusable/DocumentVerificationReview/DocumentImageViewer";
import ReviewHeader from "components/reusable/ReviewHeader";
import { ReviewCreateResponse, useReviewCreate } from "mutations";
import { Review } from "types/review";
import { getImageLabel, getImageType } from "util/actions_helpers";

interface SpoofReviewProps {
  completed: boolean;
  callback: (response: ReviewCreateResponse) => void;
  next: () => void;
  review: Review;
}

const SpoofReview: React.FC<SpoofReviewProps> = ({
  next,
  review,
  callback,
  completed,
}) => {
  const [fraud, setFraud] = useState<"yes" | "no" | "image_unusable" | null>(
    null,
  );
  const [fraudType, setFraudType] = useState<
    "photo" | "video" | "other" | null
  >(null);
  const reviewCreate = useReviewCreate();

  const handleRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFraud(event.target.value as "yes" | "no" | "image_unusable");
  };

  const handleSubmit = async () => {
    const payload = {
      id: review.id,
      fraud: fraud === "yes",
      image_unusable: fraud === "image_unusable",
      fraud_type: fraudType!,
    };

    const resp = await reviewCreate.mutateAsync(payload);
    callback?.(resp);
    next?.();
    setFraud(null);
    setFraudType(null);
  };

  if (!review) {
    return null;
  }

  const carousel: React.ReactNode[] = [];
  let idImages: string[] = [];

  (["source", "service", "targets"] as const).forEach((image) => {
    if (typeof review[image] === "string" && review[image]) {
      carousel.push(
        <DocumentImageViewer
          key={review[image]}
          label={getImageLabel(review[image])}
          imageUrls={[review[image]]}
          imageType={getImageType(review[image])}
          options={{ panZoomProps: { disableScrollZoom: true } }}
        />,
      );
    } else if (
      review[image] &&
      Array.isArray(review[image]) &&
      review[image].length > 0
    ) {
      idImages = review[image].filter((img) => img.includes("IDCard"));

      const idAuthorityPhoto = review[image].filter((img) =>
        img.includes("ID_Photo"),
      );

      if (idAuthorityPhoto[0]) {
        carousel.push(
          <DocumentImageViewer
            key={idAuthorityPhoto[0]}
            label={getImageLabel(idAuthorityPhoto[0])}
            imageUrls={idAuthorityPhoto}
            imageType={getImageType(idAuthorityPhoto[0])}
            options={{ panZoomProps: { disableScrollZoom: true } }}
          />,
        );
      }

      const livenessImages: string[] = review[image].filter(
        (img) => !img.includes("IDCard") && !img.includes("ID_Photo"),
      );
      const imageLink = livenessImages[0];
      if (imageLink) {
        carousel.push(
          <DocumentImageViewer
            key={imageLink}
            label={getImageLabel(imageLink)}
            imageUrls={livenessImages}
            options={{
              panZoomProps: {
                disableScrollZoom: true,
              },
            }}
            animate
            imageType={getImageType(imageLink)}
          />,
        );
      }
    }
  });

  if (idImages?.[0]) {
    carousel.push(
      <DocumentImageViewer
        key={idImages[0]}
        enableBoundingBox
        label={getImageLabel(idImages[0])}
        imageUrls={[idImages[0]]}
        imageType={getImageType(idImages[0])}
        options={{ panZoomProps: { disableScrollZoom: true } }}
      />,
    );
  }
  if (idImages?.[1]) {
    carousel.push(
      <DocumentImageViewer
        key={idImages[1]}
        enableBoundingBox
        label={getImageLabel(idImages[1])}
        imageUrls={[idImages[1]]}
        imageType={getImageType(idImages[1])}
        options={{ panZoomProps: { disableScrollZoom: true } }}
      />,
    );
  }

  return (
    <section className="container__content">
      <div className="review-header">
        <ReviewHeader
          title="Spoof Review"
          review={review}
          completed={completed}
          onNextReview={next}
        />
      </div>
      <div className="spoof-images">{carousel}</div>
      <div>
        {completed && review.smile_reference && (
          <div className="spoof-review-result">
            <p
              className={
                review.smile_reference?.internal.Payload.Fraud === "true"
                  ? "failure"
                  : "success"
              }
            >
              {review.smile_reference.result.ResultText}
            </p>
            <p>
              {review.smile_reference.internal.Payload.FraudType !== "null" &&
                `Fraud type: ${review.smile_reference?.internal.Payload.FraudType}`}
            </p>
          </div>
        )}
        {!completed && reviewCreate.isSuccess && (
          <div className="spoof-form">
            <div className="block--decision action--focus">
              <div className="block-action">
                <h3>Thank you</h3>
                <button
                  type="submit"
                  className="btn-secondary-inline"
                  onClick={next}
                >
                  next
                </button>
              </div>
            </div>
          </div>
        )}
        {!completed && !reviewCreate.isSuccess && (
          <PendingSpoofForm
            fraud={fraud}
            setFraudType={setFraudType}
            onClick={handleRadio}
            onSubmit={handleSubmit}
            submitted={false}
            fraudType={fraudType}
          />
        )}
      </div>
    </section>
  );
};

type PendingSpoofFormProps = {
  fraud: "no" | "yes" | "image_unusable" | null;
  setFraudType: (value: "video" | "photo" | "other") => void;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent) => void;
  submitted: boolean;
  fraudType: string | null;
};

const PendingSpoofForm: React.FC<PendingSpoofFormProps> = ({
  fraud,
  setFraudType,
  onClick,
  onSubmit,
  submitted,
  fraudType,
}) => {
  let submitContainerClassName = "not-active";
  if (
    ["no", "image_unusable"].includes(fraud!) ||
    (fraud === "yes" && fraudType)
  ) {
    submitContainerClassName = "active";
  }

  const handleFraud = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFraudType(event.target.value as "video" | "photo" | "other");
  };

  const fraudOptions = [
    { id: "type1", value: "video", label: "Video" },
    { id: "type2", value: "photo", label: "Photo" },
    { id: "type3", value: "other", label: "Other" },
  ];

  return (
    <div className="spoof-form">
      <h1>Is this Fraud?</h1>
      <div className="block--decision decision-yes">
        <div className="block-option thumb">
          <input
            type="radio"
            name="radiogroup1"
            id="rd1"
            value="yes"
            checked={fraud === "yes"}
            onChange={onClick}
          />
          <label htmlFor="rd1" className="thumb-up">
            Yes
          </label>
          <input
            type="radio"
            name="radiogroup1"
            id="rd2"
            value="no"
            checked={fraud === "no"}
            onChange={onClick}
          />
          <label htmlFor="rd2" className="thumb-down">
            No
          </label>
        </div>
        {fraud === "yes" && (
          <div
            className={`block--sub-decision${fraudType ? " action--nofocus" : " action--focus"}`}
          >
            <h2>What type?</h2>
            <div className="block-option multi">
              {fraudOptions.map(({ id, value, label }) => (
                <React.Fragment key={id}>
                  <input
                    type="radio"
                    name="radiogroup2"
                    id={id}
                    value={value}
                    checked={fraudType === value}
                    onChange={handleFraud}
                  />
                  <label
                    htmlFor={id}
                    className={`btn-default ${id.split("type")[1]} fraud-radio`}
                  >
                    {label}
                  </label>
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
        <div className={`block-action ${submitContainerClassName}`}>
          <button
            className="btn-primary"
            onClick={onSubmit}
            disabled={submitted}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SpoofReview;
