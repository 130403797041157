import React from "react";
import classNames from "classnames";

import CompareReviewImage from "components/reusable/CompareReview/CompareReviewImage";
import ConfidencePanel, {
  ConfidenceModal,
  ResultsPanel,
} from "components/reusable/CompareReview/Panels";
import { useReviewCreate } from "mutations";
import ReviewHeader from "../ReviewHeader";

function CompareReview({ callback, review, completed, next }) {
  const [sourceGood, setSourceGood] = React.useState();
  const [targetGood, setTargetGood] = React.useState();
  const [showConfidence, setShowConfidence] = React.useState(false);
  const [confidence, setConfidence] = React.useState();
  const [sourceErrors, setSourceErrors] = React.useState(new Set());
  const [targetErrors, setTargetErrors] = React.useState(new Set());
  const [disabled, setDisabled] = React.useState(false);
  const reviewCreate = useReviewCreate();

  const hasFatalErrors = () =>
    sourceErrors.has("image_unusable") ||
    targetErrors.has("image_unusable") ||
    sourceErrors.has("fraud") ||
    targetErrors.has("fraud");

  React.useEffect(() => {
    let _showConfidence;
    let disabled = true;
    let left;
    let right = false;
    if (sourceGood === true && targetGood === true) {
      _showConfidence = true;
      if (confidence) {
        disabled = false;
      }
    } else {
      if (
        sourceGood === true ||
        (sourceGood === false && sourceErrors.size > 0)
      ) {
        left = true;
      }
      if (
        targetGood === true ||
        (targetGood === false && targetErrors.size > 0)
      ) {
        right = true;
      }
      if (left && right && !hasFatalErrors()) {
        _showConfidence = true;
      }
    }
    if ((left && right && confidence) || hasFatalErrors()) {
      disabled = false;
    }
    setShowConfidence(_showConfidence);
    setDisabled(disabled);
  }, [confidence, sourceGood, targetGood, sourceErrors, targetErrors]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      id: review.id,
      review: {
        target_errors: Array.from(targetErrors),
        source_errors: Array.from(sourceErrors),
        confidence,
      },
    };

    const resp = await reviewCreate.mutateAsync(payload);
    callback?.(resp);
    next();
  };

  const question =
    parseInt(review.job.partner_params.job_type, 10) === 1
      ? "Does this person match this ID Photo?"
      : "Is this the same person?";

  const targetImages =
    review.targets || (review.target ? [review.target] : undefined);

  return (
    <section className="container__content">
      <ReviewHeader
        title="Compare Review"
        completed={completed}
        review={review}
        onNextReview={next}
      />

      <div className="comparison__content">
        <div className="comparison__content--images">
          <CompareReviewImage
            className="comparison__content__document_item"
            imageUrls={[review.source]}
            completed={completed}
            isControlGood={sourceGood}
            setIsControlGood={setSourceGood}
            setErrors={setSourceErrors}
            errors={sourceErrors}
            left
            qualityName="sourceGood"
            errorsName="sourceErrors"
            images={review.images?.source ? [review.images.source] : undefined}
          />

          {targetImages && (
            <CompareReviewImage
              className="target-image"
              imageUrls={targetImages}
              completed={completed}
              isControlGood={targetGood}
              setIsControlGood={setTargetGood}
              setErrors={setTargetErrors}
              errors={targetErrors}
              left={false}
              qualityName="targetGood"
              errorsName="targetErrors"
              images={review.images?.target ? review.images.target : undefined}
            />
          )}
        </div>

        <h1
          className={classNames({
            question: sourceErrors.size > 0 || targetErrors.size > 0,
          })}
        >
          {question}
        </h1>
        {reviewCreate.isSuccess && (
          <ConfidenceModal
            confidence={confidence}
            next={next}
            setConfidence={setConfidence}
          />
        )}
        {review.smile_reference && (
          <ResultsPanel smileReference={review.smile_reference} />
        )}
        <div className="clear-block" />
        {showConfidence && (
          <ConfidencePanel
            confidence={confidence}
            setConfidence={setConfidence}
          />
        )}

        {!completed && (
          <div>
            <button
              className="btn-primary"
              disabled={disabled || reviewCreate.isPending}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

export default CompareReview;
