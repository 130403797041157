import React, { useState, ChangeEventHandler } from "react";

import {
  ReviewCreateResponse,
  ReviewCreateInput,
  useReviewCreate,
} from "mutations";
import KE_ALIEN_CARD_Back from "resources/img/id_provider_cards/KE/ALIEN_CARD_Back_Masked.png";
import KE_ALIEN_CARD_Front from "resources/img/id_provider_cards/KE/ALIEN_CARD_Front_Masked.png";
import KE_NATIONAL_ID_Back from "resources/img/id_provider_cards/KE/NATIONAL_ID_Back_Masked.png";
import KE_NATIONAL_ID_Front from "resources/img/id_provider_cards/KE/NATIONAL_ID_Front_Masked.png";
import KE_PASSPORT from "resources/img/id_provider_cards/KE/PASSPORT_Masked.png";
import type { Review } from "types/api/review";
import { decode } from "util/route_util";
import Carousel from "./carousel";
import ReviewImage from "./review_image";
import TimeoutReview from "./TimeoutReview";

interface Props {
  completed: boolean;
  callback: (response: ReviewCreateResponse) => void;
  next: () => void;
  review: Review;
}

const IDCardReview: React.FC<Props> = ({
  review,
  next,
  callback,
  completed,
}) => {
  const [rejectionReason, setRejectionReason] = useState<string | undefined>(
    review.complete && review.smile_reference
      ? review.smile_reference.internal.Payload.RejectionReason
      : undefined,
  );
  const [error, setError] = useState("");
  const reviewCreate = useReviewCreate();

  const validateForm = (
    review: ReviewCreateInput["review"],
  ): [boolean, string | undefined] => {
    let valid = true;
    let errorMsg: string | undefined;
    review?.links?.textual_comparisons?.forEach((comparison) => {
      if (
        comparison.key === "id_type" &&
        comparison.result !== "correct" &&
        !rejectionReason
      ) {
        valid = false;
        errorMsg =
          "An ID rejection reason must be selected if the ID is invalid.";
      } else if (!comparison.result) {
        valid = false;
        errorMsg = `A decision on ${comparison.key
          .replace("_", " ")
          .toUpperCase()} is required`;
      }
    });
    return [valid, errorMsg];
  };

  const handleSubmit: React.FormEventHandler = async (e) => {
    e.preventDefault();

    const payload = {
      review: {
        textual_comparisons: review.links?.textual_comparisons,
        rejection_reason: rejectionReason || null,
      },
      id: review.id,
    };

    const [valid, errorMsg] = validateForm(payload.review);
    if (!valid) {
      setError(errorMsg!);
      return;
    }

    const resp = await reviewCreate.mutateAsync(payload);
    callback?.(resp);
    next();
  };

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    if (e.target.name === "rejection_reason") {
      setRejectionReason(e.target.value);
      return;
    }
    review.links?.textual_comparisons?.forEach((comparison) => {
      if (comparison.key === e.target.name) {
        comparison.result = e.target.value;
      }
    });
  };

  let author;
  let result;
  const smileReference = review.smile_reference;

  if (completed) {
    result = (
      <div className="block--decision">
        {smileReference && smileReference.result.ConfidenceValue && (
          <p
            className={
              smileReference.result.ConfidenceValue === "100"
                ? "success"
                : "failure"
            }
          >
            {smileReference.result.ResultText}
          </p>
        )}
      </div>
    );
  }

  if (completed) {
    author = review.author;
  } else {
    author = (decode(localStorage.token) as Record<string, string>).email;
  }
  const textualComparisons = [];
  let idType = "";
  if (review.links?.textual_comparisons || review.reviewed_text) {
    let comparisons;
    if (completed) {
      comparisons = smileReference
        ? smileReference.internal.Payload.Comparisons
        : [];
    } else {
      comparisons = review.links?.textual_comparisons;
    }
    comparisons?.forEach((comparison) => {
      let options;
      if (comparison.key.includes("name")) {
        options = [
          <option key="blank" value={undefined} />,
          <option key="correct" value="correct">
            CORRECT NAME
          </option>,
          <option key="misspelt" value="misspelt">
            MISSPELT NAME
          </option>,
          <option key="mismatch" value="mismatch">
            NAME MISMATCH
          </option>,
          <option key="invalid" value="invalid">
            INVALID NAME
          </option>,
        ];
      } else if (comparison.key === "id_type") {
        const field = comparison.key.replace("_", " ").toUpperCase();
        idType = comparison.value.toUpperCase();
        options = [
          <option key="blank" value={undefined} />,
          <option key="correct" value="correct">
            CORRECT {field}
          </option>,
          <option key="incorrect" value="incorrect">
            INCORRECT {field}
          </option>,
        ];
      } else {
        const field = comparison.key.replace("_", " ").toUpperCase();
        options = [
          <option key="blank" value={undefined} />,
          <option key="correct" value="correct">
            CORRECT {field}
          </option>,
          <option key="incorrect" value="incorrect">
            INCORRECT {field}
          </option>,
          <option key="invalid" value="invalid">
            INVALID {field}
          </option>,
        ];
      }
      let selectOrResult;
      const field = comparison.key.includes("name")
        ? "NAME"
        : comparison.key.replace("_", " ").toUpperCase();
      if (completed) {
        selectOrResult = (
          <label>
            {comparison.result} {field}
          </label>
        );
      } else {
        selectOrResult = (
          <select
            aria-label="Comparison result"
            name={comparison.key}
            value={comparison.result}
            disabled={reviewCreate.isPending}
            onChange={handleChange}
          >
            {options}
          </select>
        );
      }

      textualComparisons.push(
        <tr key={comparison.key}>
          <td>
            <label>{comparison.key.replace("_", " ").toUpperCase()}</label>
          </td>
          <td>
            <label data-testid="comparison-value">{comparison.value}</label>
          </td>
          <td>{selectOrResult}</td>
        </tr>,
      );
    });
    const rejectionOptions = [<option key="blank" value={undefined} />];
    [
      "INVALID",
      "BLURRY",
      "UNREADABLE",
      "DAMAGED",
      "EXPIRED",
      "FRAUDULENT",
    ].forEach((reason) => {
      rejectionOptions.push(
        <option key={reason} value={reason}>
          {reason === "INVALID"
            ? "INVALID / INCORRECT / UNAUTHORIZED"
            : reason.toUpperCase()}{" "}
          ID
        </option>,
      );
    });
    let selectOrReason;
    if (completed) {
      selectOrReason = (
        <label>
          {smileReference
            ? smileReference.internal.Payload.RejectionReason
            : "Unknown"}
        </label>
      );
    } else {
      selectOrReason = (
        <select
          aria-label="Rejection reason"
          name="rejection_reason"
          value={rejectionReason}
          disabled={reviewCreate.isPending}
          onChange={handleChange}
        >
          {rejectionOptions}
        </select>
      );
    }

    textualComparisons.push(
      <tr key="rejection_reason">
        <td>
          <label>ID Rejection Reason</label>
        </td>
        <td>
          <label />
        </td>
        <td>{selectOrReason}</td>
      </tr>,
    );
  }

  const frontTemplate = {
    KE_ALIEN_CARD: KE_ALIEN_CARD_Front,
    KE_NATIONAL_ID: KE_NATIONAL_ID_Front,
    KE_PASSPORT,
  }[
    `${review.country}_${idType}` as
      | "KE_ALIEN_CARD"
      | "KE_NATIONAL_ID"
      | "KE_PASSPORT"
  ];

  const backTemplate = {
    KE_ALIEN_CARD: KE_ALIEN_CARD_Back,
    KE_NATIONAL_ID: KE_NATIONAL_ID_Back,
  }[`${review.country}_${idType}` as "KE_ALIEN_CARD" | "KE_NATIONAL_ID"];

  const hasTemplates = !!frontTemplate;

  return (
    <div className="legacy container__content update-image__container">
      {!completed && (
        <>
          <div className="newsmile-heading-h1 update-image__header">
            Verify ID Card
          </div>

          <div className="update-image__details">
            <div className="update-image__detail">
              <div className="update-image__label">User ID</div>
              <div>{review.job.partner_params.user_id}</div>
            </div>

            <div className="update-image__detail">
              <div className="update-image__label">Partner</div>
              <div>{review.job.partner_id}</div>
            </div>

            <div className="update-image__detail">
              <div className="update-image__label">Job ID</div>
              <div>{review.job.job_id}</div>
            </div>

            {review.created_at && (
              <div className="update-image__detail">
                <div className="update-image__label">Created at</div>
                <div>{new Date(review.created_at).toLocaleString()}</div>
              </div>
            )}

            <div className="update-image__detail">
              <div className="update-image__label">Author</div>
              <div>{author}</div>
            </div>
            {review.wait_time && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>Time left on review:</div>
                <TimeoutReview
                  reviewId={review.id}
                  waitTime={review.wait_time}
                  onNextReview={next}
                />
              </div>
            )}
          </div>
        </>
      )}

      {completed && (
        <ul className="sidebar_jobid">
          <li>
            <span>ID Card Review</span>
          </li>
          <li>
            <label>Job id</label>
            <span id="jobid_number">{`${review.job.partner_id}-${review.job.job_id}`}</span>
          </li>
          {review.first_sent_at && (
            <li>
              <label>First sent at</label>
              <span id="jobid_firstsentat">
                {new Date(review.first_sent_at).toLocaleString()}
              </span>
            </li>
          )}
          <li>
            <label>Author</label>
            <span id="jobid_autor">{review.author}</span>
          </li>
        </ul>
      )}

      <div className="review--id-card">
        <div className="review-table-container">
          <h3 className="review-templates--title">User Input Data Check</h3>
          <table>
            <thead>
              <tr>
                <th>Field Name</th>
                <th>{hasTemplates ? "ID Authority" : "User Input"}</th>
                <th>Decision</th>
              </tr>
            </thead>

            <tbody>{textualComparisons.map((row) => row)}</tbody>
          </table>
          <div className="review__actions">
            {error && <p className="failure">{error}</p>}
            {!completed && (
              <button
                className="btn review-button btn-secondary"
                onClick={handleSubmit}
                disabled={reviewCreate.isPending}
              >
                Submit Review
              </button>
            )}
          </div>
        </div>

        <div id="submitted_id_card_review">
          <h3 className="review-templates--title">Submitted ID card</h3>
          <ReviewImage
            showResetZoom
            zoom
            pan
            left={false}
            variant="id_card_review"
            source={review.source}
          />
        </div>
      </div>
      {result}
      {hasTemplates && (
        <>
          <h3 className="review-templates--title">ID Card Templates</h3>
          <div className="review-templates review-card">
            <Carousel infinite={false} centerMode={false}>
              {frontTemplate && (
                <ReviewImage zoom left={false} source={frontTemplate} />
              )}
              {backTemplate && (
                <ReviewImage zoom left={false} source={backTemplate} />
              )}
            </Carousel>
          </div>
        </>
      )}
    </div>
  );
};

export default IDCardReview;
