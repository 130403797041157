import React from "react";
import TimeoutReview from "components/reusable/TimeoutReview";

function ConfidencePanel({ confidence, setConfidence }) {
  const options = [
    { value: 0, label: "Definitely Not Same" },
    { value: 1, label: "Probably Not Same" },
    { value: 2, label: "Not Sure" },
    { value: 3, label: "Probably Same" },
    { value: 4, label: "Definitely Same" },
  ];

  return (
    <div className="block--sub-decision">
      <ul className="scale-confidence">
        {options.map((option, index) => (
          <li key={`sc${index + 1}`}>
            <input
              type="radio"
              id={`sc${index + 1}`}
              name="confidence"
              value={option.value}
              checked={confidence === `${option.value}`}
              onChange={(e) => setConfidence(e.target.value)}
            />
            <label htmlFor={`sc${index + 1}`} id={`conf${index + 1}`}>
              {option.label}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}

export function ConfidenceModal({ confidence, next }) {
  const confidenceText = {
    0: "Definitely Not Same",
    1: "Probably Not Same",
    2: "Not Sure",
    3: "Probably Same",
    4: "Definitely Same",
  };

  return (
    <div
      className={`confidence-modal showIn confidence${Number(confidence) + 1}`}
      id="modal-finish"
    >
      <div className="modal--content">
        <h1 className="modal--title">{confidenceText[confidence]}</h1>
        <p>Thank you!</p>
        <div className="modal--action">
          <button className="btn-secondary-inline" onClick={next}>
            next
          </button>
        </div>
      </div>
    </div>
  );
}

export function ResultsPanel({ smileReference }) {
  const confidenceText = {
    0: "Definitely Not Same",
    1: "Probably Not Same",
    2: "Not Sure",
    3: "Probably Same",
    4: "Definitely Same",
  };

  let confidence;
  Object.keys(confidenceText).forEach((key) => {
    if (smileReference.result.ResultText.includes(confidenceText[key])) {
      confidence = Number(key) + 1;
    }
  });
  return (
    <div>
      <p id="human-result" className={`confidence${confidence}`}>
        {smileReference.result.ResultText}
      </p>
      {smileReference.internal.Payload.Errors.length > 0 && (
        <p id="errors">
          Errors: {smileReference.internal.Payload.Errors.join(", ")}
        </p>
      )}
    </div>
  );
}

export function InfoHeader({ completed, review, nextReview }) {
  return (
    <ul className="sidebar_jobid">
      <li>
        <span>Compare Review</span>
      </li>
      <li>
        <label>Job id</label>
        <span id="jobid_number">{`${review.job.partner_id}-${review.job.job_id}`}</span>
      </li>
      {!completed && (
        <li>
          <label>Created at</label>
          <span id="jobid_createat">
            {new Date(review.created_at).toLocaleString().split(",")[0]}
          </span>
        </li>
      )}
      <li>
        <label>First sent at</label>
        <span id="jobid_firstsentat">
          {new Date(review.first_sent_at).toLocaleString().split(",")[0]}
        </span>
      </li>
      {completed && (
        <li>
          <label>Author</label>
          <span id="jobid_autor">{review.author}</span>
        </li>
      )}
      {!completed && review.wait_time && (
        <li>
          <label>Time left on review:</label>
          <TimeoutReview
            reviewId={review.id}
            waitTime={review.wait_time}
            onNextReview={nextReview}
          />
        </li>
      )}
    </ul>
  );
}

export default ConfidencePanel;
