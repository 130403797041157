import { type UseQueryOptions } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import type { Review } from "types/api/review";
import {
  GetResourceInput,
  GetResourceResponse,
  useResource,
} from "./useResource";

type GetReviewsInput = Omit<GetResourceInput, "path">;

export const REVIEWS_QUERY_KEY = "reviews";

export const useReviews = (
  input: GetReviewsInput,
  options?: Omit<
    UseQueryOptions<
      GetResourceResponse<Review>,
      AxiosError<{ errors: string[] }>
    >,
    "queryKey" | "queryFn"
  >,
) =>
  useResource<Review>(
    {
      path: "/reviews",
      filters: input.filters,
      displayEnvironment: input.displayEnvironment,
    },
    REVIEWS_QUERY_KEY,
    {
      staleTime: 30 * 1000,
      ...options,
    },
  );
